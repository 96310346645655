import React from 'react'
import {useContext, useState} from 'react'
import {UserContext} from '../contexts/user-context';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'
import Image from 'react-bootstrap/Image'
import carrouselImg1 from '../img/sign-in_1.jpg';
import carrouselImg2 from '../img/sign-in_2.jpg';
import carrouselImg3 from '../img/sign-in_3.jpg';
import logo from '../img/logo.png';

function FormLogin() {
  const context = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleLoginSubmit(event) {
    event.preventDefault();
    if (email === 'mat@babacoach.com' && password === 'test') {
      context.setUser(12, 'Mat', email);
      context.setIsLogged(true);
    }
    else if (email === 'christophe@babacoach.com' && password === 'test') {
      context.setUser(12, 'Christophe', email);
      context.setIsLogged(true);
    }
    else if (email === 'olivier@babacoach.com' && password === 'test') {
      context.setUser(12, 'Olivier', email);
      context.setIsLogged(true);
    }
  }

  return (
    <section id="sign-in">
      <Container id="container-inside">
        <div id="circle-small"></div>
        <div id="circle-medium"></div>
        <div id="circle-large"></div>
        <div id="circle-xlarge"></div>
        <div id="circle-xxlarge"></div>
      </Container>
      <Container className="p-0">
        <Row className="no-gutters">
          <Col className="col-md-6 text-center pt-5">
            <div className="details">
              <Image
                className="px-4 mt-3 mb-5"
                src={logo}
                alt=""
                fluid={true}
              />
              <Carousel className="mt-2" controls={false} indicators={false}>
                <Carousel.Item>
                  <Image
                    className="mb-4"
                    src={carrouselImg1}
                    alt=""
                    fluid={true}
                    rounded={true}
                  />
                  <Carousel.Caption>
                    <h4>Find new friends</h4>
                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image
                    className="mb-4"
                    src={carrouselImg2}
                    alt=""
                    fluid={true}
                    rounded={true}
                  />
                  <Carousel.Caption>
                    <h4>Connect with the world</h4>
                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image
                    className="mb-4"
                    src={carrouselImg3}
                    alt=""
                    fluid={true}
                    rounded={true}
                  />
                  <Carousel.Caption>
                    <h4>Create new events</h4>
                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
          <Col className="col-md-6 bg-white pt-5 pt-5 pb-lg-0 pb-5">
            <div className="details2">
              <h1 className="mb-0">Sign in</h1>
              <p>Enter your email address and password to access admin panel.</p>
              <Form id="formLogin" onSubmit={handleLoginSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Login</Form.Label>
                  <Form.Control 
                    type="email" 
                    placeholder="Enter email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control 
                    type="password" 
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="float-end">
                  Sign In
                </Button>
              </Form>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FormLogin;