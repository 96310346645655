import React, { Suspense } from 'react'
import FormLogin from './components/FormLogin';
// import Visio from './components/Visio';
import Header from './components/Header/Header';

import {UserContext} from './contexts/user-context';

const Visio = React.lazy(() => import('./components/Visio'));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      isLogged: false,
    };

    this.setUser = (id, name, email) => {
      console.log('setUser ' + id + ' | ' + name + ' | ' + email);
      this.setState(state => ({
        user:
          state.user = {
            id: id,
            name: name,
            email: email
          }
      }));
    }

    this.setIsLogged = (isLogged) => {
      console.log('setIsLogged ' + isLogged);
      this.setState(state => ({
        isLogged:
          state.isLogged = isLogged
      }));
    }

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      setUser: this.setUser,
      setIsLogged: this.setIsLogged,
    };
  }

  render() {
    console.log('App render')
    let content = <FormLogin />
    let header = ''
    if (this.state.isLogged) {
      content = <Visio />
      header = <Header />
    }
    return (
    <div id="app">
      <UserContext.Provider value={this.state}>
        {header}
        <Suspense fallback={<div>Chargement...</div>}>
        {content}
        </Suspense>
      </UserContext.Provider>
    </div>
    )
  }
}

export default App;
