import {useContext} from 'react'
import {UserContext} from '../../contexts/user-context';

function UserMenu() {
  const context = useContext(UserContext);

  function handleLogout(event) {
    event.preventDefault();
    context.setUser(0, '', '');
    context.setIsLogged(false);
  }

  return (
    <>
    Welcome {context.user.name} !
    <button className="btnLogout" title="logout" onClick={handleLogout}>Logout</button>
    </>
  );
}

export default UserMenu;