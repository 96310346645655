import React from 'react'
import UserMenu  from './UserMenu';
import './Header.css';

function Header() {
  return (
    <header>
      <UserMenu />
    </header>
  );
}

export default Header;