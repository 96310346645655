import React from 'react'

export const user = {
  id: 0,
  name: '',
  email: '',
},
isLogged = false;

export const UserContext = React.createContext({
  user: {},
  setUser: (id, name, email) => {},
  isLogged: isLogged,
  setIsLogged: (isLogged) => {},
});